export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’ai oublié mon mot de passe"])},
  "flash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© Flash - "])},
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecrivez-nous"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez ce formulaire pour entrer en contact avec nous. Nous vous repondons dans les 24h."])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "fulname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "buttonBuyPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat unité"])},
  "buttonOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez"])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
  "buttonbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
  "buttonsell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendre"])},
  "accesstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à vos services du quotidien grâce à Bitcoin !"])},
  "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez une nouvelle façon d'accéder à vos services essentiels grâce à Bitcoin. Achetez vos unités mobiles et forfaits en toute sécurité avec la puissance du Lightning Network."])},
  "exchangeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échangez vos Bitcoins en toute simplicité grâce à Lightning !"])},
  "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec Lightning, vous pouvez effectuer des transactions Bitcoin de manière rapide et pratique. Profitez d'une expérience fluide et sécurisée pour échanger vos Bitcoins en un éclair. Découvrez la puissance de Lightning pour des échanges instantanés et sans tracas !"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a été envoyée avec succès."])},
  "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes en maintenance pour le moment. Merci d'écrire directement au numéro habituel ou pouvez revenir plus tard"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez le pouvoir de Bitcoin, à partir de 100 XOF !"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez au Bitcoin à partir de 100 Francs CFA pour simplifier vos achats de services et forfaits mobiles. Explorez un nouveau monde de possibilités financières !"])},
  "but2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Accédez au Bitcoin à partir de 100 XOF pour simplifier vos achats de services et forfaits mobiles. Explorez un nouveau monde de possibilités financières !"])},
  "but1a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passionnés de Bitcoin et de sa technologie, nous travaillons à vous permettre d’accéder aux services du quotidien grâce au Bitcoin. Vous pouvez facilement acheter vos unités mobiles, forfaits et autres avec Bitcoin."])},
  "achetez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achetez vos différents forfaits et unités des réseaux mobiles ( MTN, MOOV ) grâce à la puissance du Lightning Network."])},
  "catalogueT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment acheter vos unités ?"])},
  "catalogueP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Choisissez votre réseau (MTN ou MOOV)"])},
  "catalogueP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Choisissez votre forfait et le montant"])},
  "catalogueP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Recevez une facture Lightning"])},
  "catalogueP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Réglez la facture"])},
  "catalogueP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Soumettez les données demandées"])},
  "catalogueP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Recevez le forfait indiqué"])},
  "factureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment acheter vos satoshis (bitcoin) en quelques clics ?"])},
  "factureP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Indiquez le montant de satoshi à acheter"])},
  "factureP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Envoyez le montant indiqué par le moyen de payement de votre choix"])},
  "factureP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Recevez vos satoshis"])},
  "factureTt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment vendre vos satoshis (bitcoin) en quelques clics ?"])},
  "factureP11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Indiquez le montant de satoshi à vendre"])},
  "factureP22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Réglez la facture que vous recevrez"])},
  "factureP33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["• Recevez votre argent en Franc CFA"])},
  "obtenez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez vos premiers satoshis ( bitcoin ) grâce au Lightning Network."])},
  "tarifs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs"])},
  "investissement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echangez facilement vos satoshis via FlashX."])},
  "tarifCommun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquérir des sats"])},
  "tarifCommunT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitez du meilleur de Flash sans limite."])},
  "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez ici quelques services qui acceptent les payements par Bitcoin :"])},
  "needOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous acceptez des payements en Bitcoin ou vous connaissez des entreprises qui en acceptent, n'hésitez pas à nous informer."])},
  "clientMicrofinanceT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitez du meilleur de Flash sans limite."])},
  "faqT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puis je acheter des forfaits et unités sur n’importe quel réseau GSM ?"])},
  "faqT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puis je bénéficier de votre service partout en Afrique ?"])},
  "faqT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est le montant minimal d’achat de Bitcoin sur FlashX ?"])},
  "faqT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puis-je acheter des Bitcoins Onchain avec FlashX ? "])},
  "ans1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui vous pouvez acheter des forfaits et unités sur n’importe quel réseau GSM avec FlashPayment. Cependant seuls les réseaux MTN-Bénin et MOOV-Bénin sont disponibles sur FlashPayment actuellement."])},
  "ans2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous travaillons à vous faire bénifier de nos services peu importe votre position. Cependant, bénéficiez de nos services 24H/7 depuis : le Bénin, le Togo, le Burkina-Faso et la Côte-d'Ivoire."])},
  "ans3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur FlashX, obtenir des Bitcoins, c'est selon votre bourse. A partir de 100Fr vous pouvez obtenir du Bitcoin grâce à la puissance Lightning Network."])},
  "ans4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous travaillons à vous permettre de pouvoir acheter des bitcoins OnChain avec Flash. Mais seuls les payements via Lightning Network sont disponibles actuellement sur FlashX."])},
  "Together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensemble pour une autonomisation des jeunes & PME en Afrique avec Bitcoin"])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "contactT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])},
  "powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propulsé par BlockSolut"])},
  "succedT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande envoyée"])},
  "succedP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande a été envoyée avec succès. Merci de patienter pour le traitement."])}
}