import { createApp } from "vue";
import App from "./App.vue";
import rooter from "../src/router/index";
import install from "./install";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import "./registerServiceWorker";
import i18n from "./i18n";
import vuex from "./store";
import AOS from "aos";
import "aos/dist/aos.css";
import VueWriter from "vue-writer";
import Toaster from "@incuca/vue3-toaster";
import easySpinner from "vue-easy-spinner";
import vClickOutside from "click-outside-vue3";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import VueQrcode from "@chenfengyuan/vue-qrcode";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: "G-2X7TJKCMST",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore();
export const db = getFirestore(app);

app.AOS = new AOS.init({ disable: "none" });

createApp(App)
  .use(i18n)
  .use(vuex)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(install)
  .use(rooter)
  .use(AOS)
  .use(VueWriter)
  .use(Toaster)
  .component(VueQrcode.name, VueQrcode)
  .use(easySpinner, {
    /*options*/
    prefix: "easy",
  })
  .use(vClickOutside)
  .mount("#app");
