/**
 * PROJECT Trip
 * Created by WebStorm.
 * Samadou SALAHOU<samadousalahou@gmail.com)
 * Date 05/06/2023
 * TIME 10:22
 **/

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const vuex = createStore({
  plugins: [createPersistedState()],
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    async register(context, { email, password, name }) {
      const auth = getAuth();
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (response) {
        context.commit("SET_USER", response.user);
        response.user.updateProfile({ displayName: name });
      } else {
        throw new Error("Unable to register user");
      }
    },

    async logIn(context, { email, password }) {
      const auth = getAuth();
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit("SET_USER", response.user);
      } else {
        throw new Error("Login failed");
      }
    },

    async logOut(context) {
      const auth = getAuth();
      await signOut(auth);
      context.commit("SET_USER", null);
    },

    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          name: user.name,
          email: user.email,
        });
      } else {
        context.commit("SET_USER", null);
      }
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
  },
});

export default vuex;
