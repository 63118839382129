import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
const routes = [
  {
    path: "/",
    name: "Home",
    component: pathLoader("home"),
  },

  /* Components Root */
  {
    path: "/",
    name: "Header",
    component: pathLoader("./components/header/index.vue"),
  },
  {
    path: "/",
    name: "Footer",
    component: pathLoader("./components/footer/index.vue"),
  },
  {
    path: "/",
    name: "Form",
    component: pathLoader("./components/form/index.vue"),
  },
  {
    path: "/success",
    name: "Success",
    component: pathLoader("success"),
  },
  {
    path: "/tARegister",
    name: "Register",
    component: pathLoader("Registerr"),
  },
  {
    path: "/lBLogin",
    name: "Login",
    component: pathLoader("Login"),
  },
  {
    path: "/yBdashboard",
    name: "dashboard",
    component: pathLoader("dashboard"),
  },
  {
    path: "/succed",
    name: "succed",
    component: pathLoader("succed"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
